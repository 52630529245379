import React from 'react';

const SuccessMessage = ({ file, setFile, setSuccess }) => {
	return (
		<div className='success-message' data-testid='success-message'>
			<button
				onClick={() => {
					setFile(null);
					setSuccess(false);
				}}
				className='message-dismiss success'
			>
				dismiss
			</button>
			<p className='success'>
				Success! Your photo <b>{file.name}</b> has been uploaded.
			</p>
		</div>
	);
};

export default SuccessMessage;

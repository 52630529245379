import React, { useState } from 'react';
import { auth } from '../../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import SignOut from './SignOut';
import AuthModal from './AuthModal';

const AuthStatus = () => {
	const [loggedInUser, setLoggedInUser] = useState(false);

	onAuthStateChanged(auth, (currentUser) => {
		setLoggedInUser(currentUser);
	});

	return (
		<>
			{loggedInUser && (
				<div data-testid='auth-signed-in' className='auth-signed-in'>
					<p className='auth-welcome-message'>
						Welcome,{' '}
						<span className='username'>{loggedInUser.email}</span>!
					</p>
					<SignOut />
				</div>
			)}
			{!loggedInUser && <AuthModal />}
		</>
	);
};

export default AuthStatus;

import React, { useEffect } from 'react';
import useStorage from '../hooks/useStorage';
import { motion } from 'framer-motion';

const ProgressBar = ({ file, setError, setSuccess }) => {
	const { progress, error, success } = useStorage(file);

	useEffect(() => {
		if (error) {
			setError(error.message);
			setSuccess(false);
		}
		if (success) {
			setSuccess(success);
			setError(null);
		}
	}, [error, setError, success, setSuccess]);

	return (
		<motion.div
			initial={{ width: 0 }}
			animate={{ width: progress + '%' }}
			className='progress-bar'
		/>
	);
};

export default ProgressBar;

import { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { projectStorage, projectFirestore, auth } from '../firebase/config';

const useStorage = (file) => {
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState(null);
	const [url, setUrl] = useState(null);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		// create references
		const storageRef = ref(projectStorage, file.name);
		const collectionRef = collection(projectFirestore, 'images');

		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				let percentage =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setProgress(percentage);
			},
			(error) => setError(error),
			async () => {
				await getDownloadURL(uploadTask.snapshot.ref)
					.then((url) => {
						setUrl(url);
						addDoc(collectionRef, {
							url,
							createdAt: Timestamp.now(),
							createdBy: auth.currentUser.uid,
						})
							.then((docRef) => setSuccess(true))
							.catch((error) => setError(error));
					})
					.catch((error) => setError(error));
			}
		);
	}, [file]);

	return { progress, url, error, success };
};

export default useStorage;

import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase/config';

const SignOut = () => {
	const logoutUser = async () => {
		await signOut(auth);
	};
	return (
		<button
			data-testid='signout-button'
			className='login-register-signout-button'
			onClick={logoutUser}
		>
			Sign Out
		</button>
	);
};

export default SignOut;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDvdKFAoPPw7i8HDB_V10nGE37p0VFkAlM',
	authDomain: 'react-firegram-b292e.firebaseapp.com',
	projectId: 'react-firegram-b292e',
	storageBucket: 'react-firegram-b292e.appspot.com',
	messagingSenderId: '667148188464',
	appId: '1:667148188464:web:b4e58aae45a50f05c7a6f5',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth
const auth = getAuth(app);

// Initialize Storage
const projectStorage = getStorage();
const projectFirestore = getFirestore();

export { projectStorage, projectFirestore, auth };

import React from 'react';
import useFirestore from '../hooks/useFirestore';
import { motion } from 'framer-motion';

const ImageGrid = ({ setSelectedImg }) => {
	const { docs, loading } = useFirestore('images');

	return docs.length === 0 && !loading ? (
		<div className='empty-or-unauthorized-image-view'>
			<i>You have no photos to view.</i>
		</div>
	) : (
		<div className='img-grid'>
			{docs.map((doc) => (
				<motion.div
					layout
					whileHover={{ opacity: 1 }}
					className='img-wrap'
					key={doc.id}
					onClick={() => setSelectedImg(doc.url)}
				>
					<motion.img
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ delay: 1 }}
						src={doc.url}
						alt='uploaded pic'
					/>
				</motion.div>
			))}
		</div>
	);
};

export default ImageGrid;

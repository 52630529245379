import React, { useState } from 'react';
import ProgressBar from './ProgressBar';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';

const UploadForm = () => {
	const [file, setFile] = useState(null);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const types = ['image/png', 'image/jpeg'];

	const changeHandler = (event) => {
		let selected = event.target.files[0];

		if (selected && types.includes(selected.type)) {
			setFile(selected);
			setError('');
		} else {
			setFile(null);
			setError('Please select an image file (PNG or JPEG)');
		}
	};

	return (
		<form id='uploadForm'>
			<label
				id='uploadLabel'
				data-testid='upload-file'
				htmlFor='uploadFile'
			>
				<input id='uploadFile' type='file' onChange={changeHandler} />
				<span>+</span>
			</label>
			<div data-testid='output' className='output'>
				{file && error && (
					<ErrorMessage
						file={file}
						error={error}
						setFile={setFile}
						setError={setError}
					/>
				)}

				{success === true && (
					<SuccessMessage
						file={file}
						setFile={setFile}
						setSuccess={setSuccess}
					/>
				)}
				{file && (
					<ProgressBar
						file={file}
						setFile={setFile}
						setError={setError}
						setSuccess={setSuccess}
					/>
				)}
			</div>
		</form>
	);
};

export default UploadForm;

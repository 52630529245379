import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/config';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const handleSubmit = async (event) => {
		event.preventDefault();
		const emailInput = event.target.elements[0].value;
		const passwordInput = event.target.elements[1].value;
		try {
			const user = await signInWithEmailAndPassword(
				auth,
				emailInput,
				passwordInput
			);
			console.log(user);
			setError('');
		} catch (e) {
			setError(e.message);
			setEmail('');
			setPassword('');
		}
	};

	return (
		<>
			<form className='login-and-register-forms' onSubmit={handleSubmit}>
				<p>Login</p>
				<input
					data-testid='email'
					value={email}
					type='email'
					placeholder='email'
					onChange={(event) => setEmail(event.target.value)}
				/>
				<input
					data-testid='password'
					value={password}
					type='password'
					placeholder='password'
					onChange={(event) => setPassword(event.target.value)}
				/>
				<button data-testid='login' type='submit'>
					Login
				</button>
			</form>
			{error && (
				<div
					data-testid='login-and-register-forms-error'
					className='login-and-register-forms-error'
				>
					<p className='error'>
						Could not log in:
						<br />
						{error}
					</p>
				</div>
			)}
		</>
	);
};

export default Login;

import React, { useState } from 'react';
import Login from './Login';
import Register from './Register';
const AuthModal = () => {
	// swap Login for Register component
	const [register, setRegister] = useState(false);
	const [open, setOpen] = useState(false);

	return (
		<>
			<button
				onClick={() => setOpen(true)}
				data-testid='login-register-button'
				className='login-register-signout-button'
			>
				Login/Register
			</button>
			{open && (
				<div className='backdrop'>
					<div className='auth-modal'>
						<button id='auth-close' onClick={() => setOpen(false)}>
							close
						</button>
						{register ? (
							<>
								<Register />
								<p>
									Already have an account? Login{' '}
									<button
										data-testid='switch-to-login-form'
										className='auth-inline-link-button'
										onClick={() => setRegister(false)}
									>
										here
									</button>
									.
								</p>
							</>
						) : (
							<>
								<Login />
								<p>
									Need an account? Register{' '}
									<button
										data-testid='switch-to-register-form'
										className='auth-inline-link-button'
										onClick={() => setRegister(true)}
									>
										here
									</button>
									.
								</p>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default AuthModal;

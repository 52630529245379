import { useState, useEffect } from 'react';
import { query, orderBy, onSnapshot, collection } from 'firebase/firestore';
import { projectFirestore } from '../firebase/config';

const useFirestore = (clxn) => {
	// we have a name collision between the collection prop and the collection function imported from Firestore. I have shortened the prop name to clxn.

	const [docs, setDocs] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		const collectionRef = collection(projectFirestore, clxn);

		const collectionQuery = query(
			collectionRef,
			orderBy('createdAt', 'desc')
		);

		setLoading(false);
		const unsubscribe = onSnapshot(collectionQuery, (querySnapshot) => {
			let documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({ ...doc.data(), id: doc.id });
			});
			setDocs(documents);
		});

		return () => {
			unsubscribe();
		};
	}, [clxn]);

	return { docs, loading };
};

export default useFirestore;

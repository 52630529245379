import React from 'react';
import { motion } from 'framer-motion';

const ImageModal = ({ selectedImg, setSelectedImg }) => {
	const handleClick = (event) => {
		if (event.target.classList.contains('backdrop')) {
			setSelectedImg(null);
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			className='backdrop'
			onClick={handleClick}>
			<motion.img
				initial={{ y: '-100vh' }}
				animate={{ y: 0 }}
				src={selectedImg}
				alt='enlarged pic'
			/>
		</motion.div>
	);
};

export default ImageModal;

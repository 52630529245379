import React from 'react';
import AuthStatus from './auth/AuthStatus';

const Title = () => {
	return (
		<div className='title'>
			<div className='masthead'>
				<div className='image-and-title'>
					<img src='camera.png' alt='Camera Icon' />
					<h1>FireGram</h1>
				</div>
				<AuthStatus />
			</div>
			<h2>Your Pictures</h2>
			<p>Upload all your mesmerizing photos to one place.</p>
		</div>
	);
};

export default Title;

import React from 'react';

const ErrorMessage = ({ error, file, setFile, setError }) => {
	return (
		<div className='error-message' data-testid='error-message'>
			<button
				onClick={() => {
					setFile(null);
					setError(null);
				}}
				className='message-dismiss error'
			>
				dismiss
			</button>
			<p className='error'>
				Error uploading <b>{file.name}</b>: {error}
			</p>
		</div>
	);
};

export default ErrorMessage;

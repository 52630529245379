import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/config';
import ImageGrid from './components/ImageGrid';
import Title from './components/Title';
import UploadForm from './components/UploadForm';
import ImageModal from './components/ImageModal';

function App() {
	const [selectedImg, setSelectedImg] = useState(null);
	useEffect(() => {
		document.title = 'Firegram';
	}, []);

	const [loggedInUser, setLoggedInUser] = useState(false);

	onAuthStateChanged(auth, (currentUser) => {
		setLoggedInUser(currentUser);
	});

	return (
		<div className='App'>
			<Title />
			{loggedInUser ? (
				<>
					<UploadForm />
					<ImageGrid setSelectedImg={setSelectedImg} />
					{selectedImg && (
						<ImageModal
							selectedImg={selectedImg}
							setSelectedImg={setSelectedImg}
						/>
					)}
				</>
			) : (
				<div className='empty-or-unauthorized-image-view'>
					<i>Please create an account or login to see photos.</i>
				</div>
			)}
		</div>
	);
}

export default App;

import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/config';

const Register = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState('');

	const handleSubmit = async (event) => {
		event.preventDefault();
		const emailInput = event.target.elements[0].value;
		const passwordInput = event.target.elements[1].value;
		const confirmPasswordInput = event.target.elements[2].value;

		if (passwordInput !== confirmPasswordInput) {
			setError('Passwords do not match');
			return;
		}

		try {
			const user = await createUserWithEmailAndPassword(
				auth,
				emailInput,
				passwordInput
			);
			console.log(user);
			setError('');
			setEmail('');
			setPassword('');
			setConfirmPassword('');
		} catch (e) {
			setError(e.message);
			setEmail('');
			setPassword('');
			setConfirmPassword('');
		}
	};
	return (
		<>
			<form className='login-and-register-forms' onSubmit={handleSubmit}>
				<p>Register</p>
				<input
					data-testid='email'
					value={email}
					type='email'
					placeholder='email'
					onChange={(event) => setEmail(event.target.value)}
				/>
				<input
					data-testid='password'
					value={password}
					type='password'
					placeholder='password'
					onChange={(event) => setPassword(event.target.value)}
				/>
				<input
					data-testid='confirm-password'
					value={confirmPassword}
					type='password'
					placeholder='confirm password'
					onChange={(event) => setConfirmPassword(event.target.value)}
				/>
				<button data-testid='register' type='submit'>
					Register
				</button>
			</form>
			{error && (
				<div
					data-testid='login-and-register-forms-error'
					className='login-and-register-forms-error'
				>
					<p className='error'>
						Could not register new user:
						<br />
						{error}
					</p>
				</div>
			)}
		</>
	);
};

export default Register;
